import { canUserAccessFeature } from "../../utils/context";

export default {
  namespaced: true,
  state: {
    appConfig: {},
    broadcastMessageWebappEnabled: false,
    dcVisitNoteUploadEnabled: false,
    diagnosisConsiderationsPage: {},
    primeAdtEncounterEnabled: false,
    relatedHistoryEnabled: false,
    upToDateEnabledForUser: false,
  },
  getters: {
    getAppConfig(state) {
      return state.appConfig;
    },

    /** Used to route broadcast message requests to WebApp API (true) vs. prime-api (false)
     */
    broadcastMessageWebappEnabled(state) {
      return state.broadcastMessageWebappEnabled;
    },
    dcVisitNoteUploadEnabled(state) {
      return state.dcVisitNoteUploadEnabled;
    },
    diagnosisConsiderationsPage(state) {
      return state.diagnosisConsiderationsPage;
    },
    primeAdtEncounterEnabled(state) {
      return state.primeAdtEncounterEnabled;
    },
    relatedHistoryEnabled(state) {
      return state.relatedHistoryEnabled;
    },
    upToDateEnabled(state) {
      return state.upToDateEnabledForUser;
    }
  },
  mutations: {
    mutateAppConfig(state, { appConfig, email }) {
      state.appConfig = appConfig;
      const bypassList = appConfig?.bypassUserList?.users;

      state.broadcastMessageWebappEnabled = canUserAccessFeature(
        appConfig?.broadcastMessageWebappEnabled,
        bypassList,
        email
      );
      state.dcVisitNoteUploadEnabled = canUserAccessFeature(
        appConfig?.dcVisitNoteUploadEnabled,
        bypassList,
        email
      );
      state.diagnosisConsiderationsPage = appConfig.diagnosisConsiderationsPage;
      state.relatedHistoryEnabled = canUserAccessFeature(
        appConfig?.relatedHistoryEnabled,
        bypassList,
        email
      );
      state.primeAdtEncounterEnabled = canUserAccessFeature(
        appConfig?.primeAdtEncounterEnabled,
        bypassList,
        email
      );
      state.upToDateEnabledForUser = canUserAccessFeature(
        appConfig?.upToDateEnabled,
        bypassList,
        email
      );
    }
  },
  actions: {
    /** Professional's email used for bypass list eligibility. */
    async updateAppConfig({ commit, rootGetters }, appConfig) {
      const email = rootGetters["currentProfessional/email"];
      commit("mutateAppConfig", { appConfig, email });
    }
  }
};
