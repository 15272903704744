import { can, get, I } from "@/utils/permissions";
import storage, { KEYS } from "@/utils/storage";

export const USER_TYPES = {
  PROFESSIONAL: "professional",
  LUMERIS_ADMIN: "lumerisadmin",
  CLIENT_PARTNER: "clientpartner",
  PATIENT: "patient"
};

const hasZeusToken = () => {
  return storage.get(KEYS.ZEUS_TOKEN) || window.location.search.includes("token");
};

export const whatAmI = (permissions = get()) => {
  //override as patient when zeus token is present
  if (hasZeusToken()) {
    return USER_TYPES.PATIENT;
  }

  if (can(I.VIEW_PROGRAMS, permissions)) {
    return USER_TYPES.PROFESSIONAL;
  }
  if (can(I.ACCESS_LUMERIS_ADMIN, permissions)) {
    return USER_TYPES.LUMERIS_ADMIN;
  }
  if (can(I.ACCESS_CLIENT_PARTNER, permissions)) {
    return USER_TYPES.CLIENT_PARTNER;
  }
  if (can(I.ACCESS_SECURE_WEB_CHAT, permissions)) {
    return USER_TYPES.PATIENT;
  }

  return "unknown";
};

/**
 * Check if a user should be able to access a feature.
 * @remarks This is a check to see if a feature is enabled globally, or if the user is allowed to bypass feature flags. It is a not a substiture for role-based permissions checks.
 * @param feature { enabled: boolean } The feature to check
 * @param allowUserList string[] array of users who can bypass the feature flag check
 * @param email email address of the user as it would appear on the bypass list
 */
export const canUserAccessFeature = (feature, allowUserList, email) => {
  if (!feature) {
    return false;
  }

  if (feature.enabled) {
    return true;
  }

  if (!allowUserList || !allowUserList.length || !email) {
    return false;
  }

  return allowUserList
    .map(x => x.toLowerCase())
    .filter(x => !!x)
    .includes(email.toLowerCase());
};

export default {
  whatAmI
};
